<template>
  <BaseColorPickerDialog
    v-model="color"
    @confirm="updateSeminarFieldOfAction"
    :readOnly="!hasPermission('seminar_field_of_action.update')"
  />
</template>

<script>
import BaseColorPickerDialog from "@components/Base/BaseColorPickerDialog.vue";
import hasPermission from "@mixins/hasPermission";

export default {
  name: "ColorPicker",

  mixins: [hasPermission],

  data() {
    return {
      isLoading: false,
      color: this.initColor(),
    };
  },

  components: {
    BaseColorPickerDialog,
  },

  props: {
    fieldOfAction: {
      type: Object,
      required: true,
    },
  },

  computed: {
    payload() {
      return {
        seminarFieldOfActionId: this.fieldOfAction.id,
        color: this.color,
        icon: null,
      };
    },
  },

  methods: {
    async updateSeminarFieldOfAction() {
      this.isLoading = true;
      try {
        await this.$axios.seminarManagement.updateSeminarFieldOfAction(this.payload);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    initColor() {
      return this.fieldOfAction.color
        ? this.fieldOfAction.color
        : this.$vuetify.theme.themes.light.primary;
    },
  },
};
</script>
