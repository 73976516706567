<template>
  <ModuleSubPage fullWidth title="SEMINAR_MANAGEMENT.FIELD_OF_ACTIONS.TITLE">
    <BaseHasPermission :permissionNames="['seminar_field_of_action.read']">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-spacer />
            <v-col cols="3">
              <BaseTableSearchField
                v-model="filter.search"
                @triggerSearch="listSeminarFieldOfActions"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <SeminarFieldOfActionsTable
            :seminarFieldOfActions="seminarFieldOfActions"
            :loading="isLoading"
            :options.sync="options"
            :server-items-length="meta.total"
            @reload="listSeminarFieldOfActions"
          />
        </v-card-text>
      </v-card>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import SeminarFieldOfActionsTable from "@views/Restricted/SeminarManagement/SeminarFieldOfActions/List/partials/SeminarFieldOfActionsTable.vue";

export default {
  name: "SeminarFieldOfActions",

  components: {
    ModuleSubPage,
    SeminarFieldOfActionsTable,
  },

  data() {
    return {
      isLoading: true,
      seminarFieldOfActions: [],
      options: {
        page: 1,
        perPage: 15,
        sortBy: ["displayName"],
        sortDesc: [false],
      },

      meta: {
        total: 0,
      },

      filter: {
        search: null,
      },
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.listSeminarFieldOfActions();
      },
    },
  },

  methods: {
    async listSeminarFieldOfActions() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.seminarManagement.listSeminarFieldOfActions({
          ...this.options,
          ...this.filter,
          perPage: this.options.perPage === -1 ? 0 : this.options.perPage,
        });
        this.seminarFieldOfActions = data.data;
        this.meta = data.meta;
        if (this.options.perPage === -1) {
          this.meta = {
            total: data.data.length,
          };
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.listSeminarFieldOfActions();
  },
};
</script>
