<template>
  <v-data-table
    :headers="headers"
    :items="seminarFieldOfActions"
    v-bind="$attrs"
    v-on="$listeners"
    item-key="id"
  >
    <template #[`item.color`]="{ item }">
      <ColorPicker :fieldOfAction="item" />
    </template>
  </v-data-table>
</template>

<script>
import ColorPicker from "@views/Restricted/SeminarManagement/SeminarFieldOfActions/List/partials/ColorPicker.vue";

export default {
  name: "SeminarFieldOfActionsTable",

  components: {
    ColorPicker,
  },

  props: {
    seminarFieldOfActions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("SEMINAR_MANAGEMENT.FIELD_OF_ACTIONS.LIST.TABLE.HEADER.DISPLAY_NAME"),
          value: "tag",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.FIELD_OF_ACTIONS.LIST.TABLE.HEADER.DESCRIPTION"),
          value: "description",
          sortable: true,
        },

        {
          text: this.$t("SEMINAR_MANAGEMENT.FIELD_OF_ACTIONS.LIST.TABLE.HEADER.COLOR"),
          value: "color",
          sortable: false,
        },

        {
          value: "actions",
          align: "end",
          sortable: false,
        },
      ];
    },
  },
};
</script>
